import React from 'react';
import Profile from './images/badge3.png'
// import logo from './images/Logo.png';
import badgeFrame1 from './images/badgeframe1.png';
import badgeFrame2 from './images/badgeframe2.png';
import badgeFrame3 from './images/badgeframe3.png';
import badgeText1 from './images/badgetext2.png';
import badgeText2 from './images/badgetext3.png';
import badgeText3 from './images/badgetext1.png';

function getBadge(badges) {
    if (badges.ceoInMaking) {
        return { frame: badgeFrame3, text: badgeText3 };
    } else if (badges.problemSolver) {
        return { frame: badgeFrame2, text: badgeText2 };
    } else if (badges.smartGeneralist) {
        return { frame: badgeFrame1, text: badgeText1 };
    } else {
        return null;
    }
}

const BadgeComponent = ({ badges, wipData }) => {
    const activeBadge = getBadge(badges);
    const photoSrc = `data:image/jpeg;base64,${wipData.photo}`;

    if (!activeBadge) return null;

    return (
        <div className="mt-md-5 w-xxl-7 position-relative text-center">
            <img src={activeBadge.text} alt="badge-frame" width="100%" />
            <img
                src={photoSrc}
                alt="player-photo"
                className="position-absolute "
                style={{
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -69%)',
                    width: '60%',
                    height: '46%',
                    borderRadius: '50%',
                    objectFit: 'cover',
                }}
            />
            <img
                src={activeBadge.frame}
                alt="badge-text"
                className="badge-text"
            />
        </div>
    );
};


const Hero = ({ personalData, wipData, badges, problemSolver, smartGeneralist, ceoInMaking, logo }) => {
    return (
        // 
        // <div>
            <div className="border border-1 border-secondary rounded p-xxl-8 p-lg-5 p-md-4 p-3 mb-4 ">
                <div className="d-flex justify-content-between align-items-center mb-xxl-6">
                    <img
                        src={logo}
                        alt="logo"
                        className="img-fluid w-md-50"
                    />

                    <div className="text-end d-flex flex-column">
                        <a
                            href="https://workverse.in/"
                            className="fs-10 text-white text-decoration-none"
                        >
                            www.workverse.in
                        </a>
                        <span className="mb-0 fs-10">
                            WIP ID: <span>{wipData.wip_id}</span>
                        </span>
                    </div>
                </div>

                <div className="d-none d-md-block ">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div className="d-flex mt-md-5 justify-content-around align-items-start flex-column">
                            <h1 className="text-uppercase mb-0 fw-bold fs-h2     ">
                                workplace intelligence profile
                            </h1>
                            <div className="mt-md-5 mb-xxl-6 ">
                                <h2 className="  fw-bold green fs-h4 ">
                                    {personalData.firstName}{' '}
                                    {personalData.lastName}
                                </h2>
                                <p>Description aboubt the player</p>
                                
                            </div>
                        </div>

                        {/* <BadgeComponent
                            badges={{
                                problemSolver: problemSolver,
                                smartGeneralist: smartGeneralist,
                                ceoInMaking: ceoInMaking,
                            }}
                            wipData={wipData}
                        /> */}
                        <img src={Profile} style={{ width: "250px", height: "250px" }} alt="" />
                    </div>
                </div>
                {/* mobile view start */}
                <div className=" mt-5 d-md-none d-block">
                    <h5 className="text-uppercase  fw-bold">
                        workplace intelligence 
                    </h5>
                    <div className="d-flex justify-content-between align-items-center ">
                        <div className=" mt-4">
                            <h5 className="mb-2 fw-bold green">
                                {personalData.firstName}{' '}
                                {personalData.lastName}
                            </h5>
                        </div>
{/* 
                        <BadgeComponent
                            badges={{
                                problemSolver: problemSolver,
                                smartGeneralist: smartGeneralist,
                                ceoInMaking: ceoInMaking,
                            }}
                            wipData={wipData}
                        /> */}
                    </div>
                </div>
                {/* mobile view end*/}

                <p className="mt-5 w-100 fs-sm-20 ">
                    The WIP showcases {personalData.firstName}'s ability to
                    think, solve and communicate in a workplace setting.
                </p>
                
            </div>
            
        // </div>
    );
}

export default Hero;
