import React from 'react';

const Analysis = ({ neuroda, personalData, wipData }) => {
    return (
        <div className="mb-4 border border-1 border-secondary rounded p-xxl-6 p-lg-5 p-md-4 p-3 ">
            <h2 className="text-uppercase fw-bold ls-8 mb-4 ">
                Analysis
            </h2>
            {/* {wipData.analysis.approach_to_work.strengths.length > 0 && ( */}
            <div className={'d-flex   mb-xxl-6'}>
                <div className="neuroda d-none d-md-block">
                    <img src={neuroda} alt="neuroda" width="100%" />
                </div>
                <div className="soft-skills-wrapper bg-grey-gradient">
                    <div className="border border-1 border-secondary rounded shadow-violet soft-skills">
                        <h2 className="green m-0 fs-sm-20 d-none d-md-block word-break ">
                            {`${personalData.firstName}'s Work Competency`}
                        </h2>
                        <div className="d-md-none d-block">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2 className="green m-0 fs-sm-20">
                                    {`${personalData.firstName}'s Approach to Work`}
                                </h2>
                                <img
                                    src={neuroda}
                                    alt="neuroda"
                                    width="30%"
                                />
                            </div>
                        </div>
                        <ul className="py-4 px-xxl-6 px-lg-5 px-md-4 ps-3 mb-0 padding-left ">
                            {wipData.analysis.approach_to_work.strengths.map(
                                (a, index) => (
                                    <li
                                        className="mb-0   mb-3"
                                        key={index}
                                    >
                                        {a}
                                    </li>
                                )
                            )}
                        </ul>
                        <div className="flare3"></div>
                        <div className="flare4"></div>
                    </div>
                </div>
            </div>
            {/* )} */}

            {/* {wipData.analysis.key_traits.strengths.length > 0 && ( */}
            <div className={'d-flex  mb-5'}>
                <div className="soft-skills-wrapper1 bg-grey-gradient">
                    <div className="border border-1 border-secondary rounded shadow-violet soft-skills1">
                        <h2 className="green m-0 fs-sm-20 d-none d-md-block mb-3">
                            Recommended Job Functions
                        </h2>
                        <h3 className=" green">
                            {personalData.firstName} will be good at roles that
                            involve the following functions
                        </h3>

                        <h5 className="mb-0 ">
                            Job functions refers to generalistic functional
                            aspects of tasks that need to be done at the
                            workplace
                        </h5>

                        {/* <div className="d-md-none d-block">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2 className="green m-0 fs-sm-20">
                                    {`Key traits exhibited by ${personalData.firstName} at the workplace`}
                                </h2>
                                <img
                                    src={neuroda}
                                    alt="neuroda"
                                    width="30%"
                                />
                            </div>
                        </div> */}
                        <ul className="py-4 px-xxl-6 px-lg-5 px-md-4 ps-3 mb-0 padding-left ">
                            {/* {wipData.analysis.key_traits.strengths.map(
                                (a, index) => (
                                    <li
                                        className="mb-0  mb-3"
                                        key={index}
                                    >
                                        {a}
                                    </li>
                                )
                            )} */}

                            <li className='mb-4'>Resource Optimization-

                                Involves strategically managing resources to maximize efficiency and output with limited availability, ideal for analytical and resourceful individuals who thrive on creative problem-solving and making a significant impact through effective resource use.</li>
                            <li className='mb-4'>Tactical Thinking-

                                Involves rapidly assessing situations and creating effective plans to achieve short-term goals, ideal for decisive and resourceful individuals who excel in fast-paced, dynamic environments.</li>
                            <li>Tactical Thinking

                                Involves rapidly assessing situations and creating effective plans to achieve short-term goals, ideal for decisive and resourceful individuals who excel in fast-paced, dynamic environments.

                                Self-starting-

                                Takes initiative and drives tasks forward independently to achieve goals, suited for motivated individuals who excel in autonomy, problem-solving, and taking ownership of their work. These job functions align with Om's strengths in strategic thinking, problem-solving, and adaptability, while also acknowledging areas for improvement, such as balancing impulsivity with deliberation and considering long-term consequences.</li>
                        </ul>
                        <div className="flare3"></div>
                        <div className="flare4"></div>
                    </div>
                </div>
                <div className="neuroda1 d-none d-md-block">
                    <img src={neuroda} alt="neuroda" width="100%" />
                </div>
            </div>
            {/* )} */}
        </div>
    );
}

export default Analysis;
