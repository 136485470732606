import React from 'react';
import QRCode from 'qrcode.react';

const Footer = ({wipData , handleDownloadPdf,personalData}) => {
                    
    
  return (
    <div>
        <div className="d-flex justify-content-between align-items-center px-lg-4 px-0 mt-3">
                    <div>
                        <QRCode
                            value={wipData.playerURL}
                            size={80}
                            level={'H'}
                            includeMargin={true}
                            bgColor={'#ffffff'}
                            fgColor={'#000000'}
                        />
                        <br />
                        Scan to Verify
                    </div>

                    <button
                        onClick={handleDownloadPdf}
                        className="btn btn-primary text-black"
                        style={{ background: '#ffffff' }}
                    >
                        Download PDF
                    </button>
                </div>
                <br />
                <br />
                <br />
                <p className="px-2 px-md-0 fs-14">
                    This certificate above verifies that{' '}
                    <span className="text-primary">
                        {' '}
                        {personalData.firstName} {personalData.lastName}{' '}
                    </span>
                    successfully completed the game Master futuristic soft
                    skills and roleplay scenarios in workplace Simulation on
                    <span> 12/09/2024 </span> at{' '}
                    <span className="text-primary">
                        {' '}
                        Workverse ( X billion Skills lab)
                    </span>
                    . The certificate indicates the entire course was completed
                    as validated by the student.
                </p>
      
    </div>
  );
}

export default Footer;
