import "./App.css";
import Profile from "./Component/profile";
import { StarRating } from "./Component/rating";
import logo from "../src/Component/images/Logo.png";
import playStoreIcon from "./Component/images/playstore-icon.png";

function App() {
  const rating = 4.7;
  return (
    <div className="App ">

      <div className="hometab pb-3">
        <img src={logo} alt="logo" className="homelogo" />
        {/* <p className="white hometext">Lorem Ipsum is simply dummy Lorem dummy.</p> */}
      </div>

      <div className="row p-md-5 justify-content-evenly">
        <div className=" col-lg-9 p-0">
          <div className="p-4 Profile mb-4">
            <Profile />
          </div>
        </div>

        <div className="col-lg-3 p-4">
          <h4 className="fw-bold text-capitalize mb-2 white">
            about the workverse:
          </h4>
          <br />

          <div className="w-md-50 w-lg-100">
            <a href="https://play.google.com/store/apps/details?id=com.Workverse.Simulator">
              <img
                src="https://i.ibb.co/YZWWnRr/unnamed.webp"
                alt="game-img"
                border="0"
                width="100%"
              />
            </a>
          </div>

          <br />
          <p className="fw-bold mt-2 mb-1 white ">
            Master futuristic soft skills and roleplay scenarios in workplace
            Simulation
          </p>
          <p className="mb-0 white">Workverse ( X billion Skills lab)</p>
          <div>
            <StarRating rating={rating} />
          </div>
          <h5 className="fw-bold mt-1 white mb-3">₹ 5000 </h5>

          <div className="w-md-50">
            <a href="https://play.google.com/store/apps/details?id=com.Workverse.Simulator">
              <img src={playStoreIcon} alt="playStoreIcon" width="50%" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
