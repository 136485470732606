// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import QRCode from 'qrcode.react';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
// import { useRef } from 'react';
// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';
// import logo from './images/Logo.png';
// // import badge3 from "./images/badge3.png";
// import trainingicon1 from './images/Vector (3).png';
// import trainingicon2 from './images/Vector.png';
// import trainingicon3 from './images/Vector (1).png';
// import trainingicon4 from './images/Engage Email  (Icon).svg';
// import trainingicon5 from './images/Kamai Token (Icon).svg';
// import trainingicon6 from './images/Vector (2).png';
// import traitsicon1 from './images/Vector (4).png';
// import traitsicon2 from './images/Vector (5).png';
// import traitsicon3 from './images/Vector (6).png';
// // import playerImg from "./images/player_img.png";
// // import traitsicon4 from "./images/Vector.svg";
// import badgeicon1 from './images/badge1.png';
// import badgeicon2 from './images/badge2.png';
// import badgeicon4 from './images/badge4.png';
// import neuroda from './images/Neuroda.png';
// // import communicate from "./images/COMMUNICATE.png";
// import badgeFrame1 from './images/badgeframe1.png';
// import badgeFrame2 from './images/badgeframe2.png';
// import badgeFrame3 from './images/badgeframe3.png';
// import badgeText1 from './images/badgetext2.png';
// import badgeText2 from './images/badgetext3.png';
// import badgeText3 from './images/badgetext1.png';
// // import solve from "./images/SOLVE.png";
// import think from './images/THINK.png';
// import './profile.css';
// import Hero from '../Component/Hero.js'

// const getColorAndLabel = (score) => {
//     let color, label;

//     if (score < 55) {
//         color = '#19f2fa';
//         label = 'Capable';
//     } else if (score < 77) {
//         color = '#02b372';
//         label = 'Impressive';
//     } else if (score <= 100) {
//         color = '#ff529d';
//         label = 'Exceptional';
//     }

//     return { color, label };
// };

// export default function Profile() {
//     const [wipData, setWipData] = useState();
//     const [personalData, setPersonalData] = useState();
//     const [kamaiData, setKamaiData] = useState();
//     const [ceoInMaking, setCEOInMaking] = useState(false);
//     const [smartGeneralist, setSmartGeneralist] = useState(false);
//     const [problemSolver, setProblemSolver] = useState(false);
//     const [error] = useState();
//     const sectionRef = useRef();

//     const badgeIcons = {
//         smart_professional: badgeicon1,
//         future_manager: badgeicon2,
//         ceo_in_making: badgeicon4,
//     };

//     const traitsIcons = [traitsicon1, traitsicon2, traitsicon3];

//     const queryString = window.location.search;
//     const urlParams = new URLSearchParams(queryString);
//     const wipId = urlParams.get('wipId');

//     useEffect(() => {
//         if (wipId) {
//             const fetchData = async () => {
//                 try {
//                     const [wipResponse, personalResponse, kamaiDataResponse] =
//                         await Promise.all([
//                             axios.get(
//                                 `https://api.engine.workverse.in/api/v2/users/wip/${wipId}`
//                             ),
//                             axios.get(
//                                 `https://api.engine.workverse.in/api/v2/users/info/${wipId}`
//                             ),
//                             axios.get(
//                                 `https://api.engine.workverse.in/api/v2/users/kamai/${wipId}`
//                             ),
//                         ]);

//                     setWipData(wipResponse.data);
//                     setPersonalData(personalResponse.data);
//                     setKamaiData(kamaiDataResponse.data);

//                     setCEOInMaking(
//                         kamaiDataResponse.data.playerBadgeData.includes(
//                             'CEO In the Making'
//                         )
//                     );
//                     setSmartGeneralist(
//                         kamaiDataResponse.data.playerBadgeData.includes(
//                             'Emerging Smart Generalist'
//                         )
//                     );
//                     setProblemSolver(
//                         kamaiDataResponse.data.playerBadgeData.includes(
//                             'Seasoned Problem Solver'
//                         )
//                     );
//                 } catch (error) {
//                     console.error('Error fetching data:', error.message);
//                 }
//             };
//             fetchData(); // Call fetchData without passing wipId as an argument
//         }
//     }, [wipId]);

//     const handleDownloadPdf = () => {
//         const input = sectionRef.current;
//         html2canvas(input, { scale: 0.9 }).then((canvas) => {
//             const imgData = canvas.toDataURL('image/png');
//             const pdf = new jsPDF('p', 'mm', 'a4');

//             const pdfWidth = pdf.internal.pageSize.getWidth();
//             const pdfHeight = pdf.internal.pageSize.getHeight();

//             const canvasWidth = canvas.width;
//             const canvasHeight = canvas.height;

//             const imgHeight = (canvasWidth * pdfHeight) / pdfWidth;
//             let heightLeft = canvasHeight;
//             let position = 0;

//             // Add the first page
//             pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
//             heightLeft -= pdfHeight;

//             // Add additional pages if necessary
//             while (heightLeft > 0) {
//                 position = heightLeft - canvasHeight;
//                 pdf.addPage();
//                 pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
//                 heightLeft -= pdfHeight;
//             }

//             pdf.save('pfd.pdf');
//         });
//     };

//     if (error) return <div className="p-4 text-white">Input: {error}</div>;
//     if (!wipData) return <div className="p-4 text-white">Loading...</div>;

//     const { overallSkillScore } = wipData.softSkills;
//     const { color, label } = getColorAndLabel(overallSkillScore);

//     const { subskills } = wipData.softSkills.useCases[0];

//     function getBadge(badges) {
//         if (badges.ceoInMaking) {
//             return { frame: badgeFrame3, text: badgeText3 };
//         } else if (badges.problemSolver) {
//             return { frame: badgeFrame2, text: badgeText2 };
//         } else if (badges.smartGeneralist) {
//             return { frame: badgeFrame1, text: badgeText1 };
//         } else {
//             return null;
//         }
//     }



//     return (
//         <>
//             <section className="p-3 p-xxl-5 text-white wip-bg" ref={sectionRef}>
//                 <div
//                     style={{
//                         width: '100%',
//                         position: 'relative',
//                     }}
//                 >
//                     <div className="flare"></div>
//                 </div>
//                 <Hero 
//                  personalData={personalData} 
//                  wipData={wipData} 
//                  badges={{ problemSolver, smartGeneralist, ceoInMaking }}
//                  problemSolver={problemSolver}
//                  smartGeneralist={smartGeneralist}
//                  ceoInMaking={ceoInMaking}
//                  logo={logo} // Pass the logo
//                 />
//                 {/* <div className="border border-1 border-secondary  rounded p-xxl-6 p-lg-5 p-md-4 p-3 ">
//                     <div className="d-flex justify-content-between align-items-center">
//                         <img
//                             src={logo}
//                             alt="logo"
//                             className="img-fluid w-md-50"
//                         />

//                         <div className="text-end d-flex flex-column">
//                             <a
//                                 href="https://workverse.in/"
//                                 className="fs-10 text-white text-decoration-none"
//                             >
//                                 www.workverse.in
//                             </a>
//                             <span className="mb-0 fs-10">
//                                 WIP ID: <span>{wipData.wip_id}</span>
//                             </span>
//                         </div>
//                     </div>

//                     <div className="d-none d-md-block ">
//                         <div className="d-flex justify-content-between align-items-center">
//                             <div className="d-flex justify-content-around align-items-start flex-column">
//                                 <h1 className="text-uppercase mb-0 fw-bold fs-h2 ">
//                                     workplace intelligence profile
//                                 </h1>
//                                 <div className="mt-md-5 mt-3 ">
//                                     <h2 className="mb-2  fw-bold green fs-h4 ">
//                                         {personalData.firstName}{' '}
//                                         {personalData.lastName}
//                                     </h2>
//                                 </div>
//                             </div>

//                             <BadgeComponent
//                                 badges={{
//                                     problemSolver: problemSolver,
//                                     smartGeneralist: smartGeneralist,
//                                     ceoInMaking: ceoInMaking,
//                                 }}
//                                 wipData={wipData}
//                             />
//                         </div>
//                     </div>
//                     {/* mobile view start */}
//                     {/* <div className=" mt-5 d-md-none d-block">
//                         <h5 className="text-uppercase  fw-bold">
//                             workplace intelligence profile
//                         </h5>
//                         <div className="d-flex justify-content-between align-items-center ">
//                             <div className=" mt-4">
//                                 <h5 className="mb-2 fw-bold green">
//                                     {personalData.firstName}{' '}
//                                     {personalData.lastName}
//                                 </h5>
//                             </div>

//                             <BadgeComponent
//                                 badges={{
//                                     problemSolver: problemSolver,
//                                     smartGeneralist: smartGeneralist,
//                                     ceoInMaking: ceoInMaking,
//                                 }}
//                                 wipData={wipData}
//                             />
//                         </div>
//                     </div>
//                     {/* mobile view end*/}

//                     {/* <p className="mt-4 m-md-0">
//                         The WIP showcases {personalData.firstName}'s ability to
//                         think, solve and communicate in a workplace setting.
//                     </p> 
//                 </div>  */}
//                 <div
//                     className="mb-3"
//                     style={{
//                         width: '100%',
//                         position: 'relative',
//                     }}
//                 >
//                     <div className="flare2"></div>
//                 </div>
//                 <div className="border border-1 border-secondary rounded p-xxl-6 p-lg-5 p-md-4 p-3 mb-4">
//                     <h2 className="text-uppercase fw-bold ls-8 mb-5">
//                         Soft skills proficiency
//                     </h2>

//                     <div className="mb-5">
//                         <div className="row">
//                             <div className="col-12 col-md-8  align-items-center">
//                                 <h3 className="text-uppercase fw-bold green">
//                                     Creative Problem Solving
//                                 </h3>
//                                 <p className="mb-4 mb-md-0">
//                                     Creative problem solving in the workplace
//                                     involves using innovative thinking and
//                                     unconventional approaches to address
//                                     challenges and enhance outcomes.
//                                 </p>
//                             </div>

//                             <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
//                                 <div className="custom-loader-wrapper">
//                                     <div
//                                         className={`skill circular-progress-${label.toLowerCase()}`}
//                                     >
//                                         <CircularProgressbar
//                                             value={
//                                                 wipData.softSkills
//                                                     .overallSkillScore
//                                             }
//                                             text={label}
//                                             strokeWidth={10}
//                                             styles={buildStyles({
//                                                 textSize: '14px',
//                                                 pathColor: color,
//                                                 textColor: color,
//                                                 trailColor: '#2c2c2c',
//                                             })}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                     <div className=" mb-5 ">
//                         <div className=" border-start ps-3 ">
//                             <h3 className="green m-0">
//                                 Finding Smartest Alternative
//                             </h3>
//                             <p>
//                                 Innovative, resourceful approach to
//                                 problem-solving that emphasizes simplicity,
//                                 adaptability, and creativity by making the most
//                                 of available resources and challenging
//                                 conventional methods.
//                             </p>
//                         </div>
//                         <div className="d-flex flex-column-reverse flex-md-row align-items-center justify-content-lg-between gap-md-3 gap-lg-0 align-items-center">
//                             <div className="d-md-flex justify-content-between w-55">
//                                 {subskills.map(
//                                     (
//                                         { subskillName, subskillScore },
//                                         index
//                                     ) => {
//                                         const { color, label } =
//                                             getColorAndLabel(subskillScore);

//                                         return (
//                                             <div
//                                                 className="custom-loader-wrapper mb-4 mb-md-0"
//                                                 key={index}
//                                             >
//                                                 <div
//                                                     className={`circular-progress-${label.toLowerCase()}`}
//                                                 >
//                                                     <CircularProgressbar
//                                                         value={subskillScore}
//                                                         text={label}
//                                                         strokeWidth={10}
//                                                         styles={buildStyles({
//                                                             textSize: '14px',
//                                                             pathColor: color,
//                                                             textColor: color,
//                                                             trailColor:
//                                                                 '#2c2c2c',
//                                                         })}
//                                                     />
//                                                 </div>
//                                                 <p className="mb-0 fs-12">
//                                                     {subskillName}
//                                                 </p>
//                                             </div>
//                                         );
//                                     }
//                                 )}
//                             </div>

//                             <div className="w-xxl-6">
//                                 <img src={think} alt="think" width="100%" />
//                             </div>
//                         </div>
//                     </div>

//                     <div className="d-flex justify-content-between flex-column flex-md-row mb-5 ">
//                         <div className="col-lg-3 col-md-3 col-12 p-0 mt-md-0  mb-5 mb-md-5 text-center text-md-start">
//                             <h4 className="fw-bold blue mb-3">Capable</h4>
//                             <p className="m-0 ">
//                                 {' '}
//                                 Demonstrates a foundational application of the
//                                 skill, able to perform tasks with occasional
//                                 support in typical workplace scenarios.,
//                             </p>
//                         </div>
//                         <div className="divider2 d-none d-md-block"></div>
//                         <div className="col-lg-3 col-md-3 col-12 p-0  mt-md-0 mb-5 mb-md-5  text-center text-md-start">
//                             <h4 className="fw-bold blue-green  mb-3">
//                                 Impressive
//                             </h4>
//                             <p className="m-0 ">
//                                 Exhibits a strong command of the skill, capable
//                                 of independently handling complex tasks and
//                                 contributing to team goals.
//                             </p>
//                         </div>
//                         <div className="divider2 d-none d-md-block"></div>
//                         <div className="col-lg-3 col-md-3 col-12 p-0  mt-lg-0  mb-md-4 mb-lg-3  text-center text-md-start">
//                             <h4 className="fw-bold pink  mb-3">Exceptional</h4>
//                             <p className="m-0 ">
//                                 Displays mastery in the skill, consistently
//                                 delivering quality results, and demonstrating
//                                 innovative approaches in addressing challenging
//                                 workplace situations.
//                             </p>
//                         </div>
//                     </div>
//                 </div>

//                 <div className="mb-4 border border-1 border-secondary rounded p-xxl-6 p-lg-5 p-md-4 p-3 ">
//                     <h2 className="text-uppercase fw-bold ls-8 mb-4 ">
//                         Demonstrated Workplace Behaviors
//                     </h2>

//                     <div className="d-flex flex-column flex-md-row justify-content-between">
//                         {wipData.traits.map((trait, index) => {
//                             const roundedScore = trait.traitScore.toFixed(1); // Round to one decimal place
//                             return (
//                                 <div
//                                     key={index}
//                                     className={`w-100 w-md-50 ${index % 2 === 0 ? 'pe-2' : 'ps-2'
//                                         } mb-4 mb-md-0`}
//                                 >
//                                     <h5 className="mb-2">{trait.traitName}</h5>
//                                     <div
//                                         className="progress mb-2"
//                                         role="progressbar"
//                                         aria-label={trait.traitName}
//                                         aria-valuenow={roundedScore}
//                                         aria-valuemin="0"
//                                         aria-valuemax="10"
//                                     >
//                                         <div
//                                             className="progress-bar"
//                                             style={{
//                                                 width: `${(roundedScore / 10) * 100
//                                                     }%`,
//                                                 backgroundColor: '#9cfc00',
//                                                 borderRadius: '15px',
//                                             }}
//                                         />
//                                     </div>
//                                     <div className="d-flex justify-content-end gap-3 align-items-center pe-7">
//                                         <p className="text-end green">
//                                             <strong>{roundedScore}</strong>
//                                         </p>
//                                         <p className="text-end">
//                                             {roundedScore <= 4
//                                                 ? 'Occasional'
//                                                 : roundedScore <= 7
//                                                     ? 'Frequent'
//                                                     : 'Consistent'}
//                                         </p>
//                                     </div>
//                                 </div>
//                             );
//                         })}
//                     </div>
//                 </div>

//                 <div className="mb-4 border border-1 border-secondary rounded p-xxl-6 p-lg-5 p-md-4 p-3 ">
//                     <h2 className="text-uppercase fw-bold ls-8 mb-4 ">
//                         Analysis
//                     </h2>
//                     {wipData.analysis.approach_to_work.strengths.length > 0 && (
//                         <div className={'d-flex   mb-xxl-6'}>
//                             <div className="neuroda d-none d-md-block">
//                                 <img src={neuroda} alt="neuroda" width="100%" />
//                             </div>
//                             <div className="soft-skills-wrapper bg-grey-gradient">
//                                 <div className="border border-1 border-secondary rounded shadow-violet soft-skills">
//                                     <h2 className="green m-0 fs-sm-20 d-none d-md-block word-break ">
//                                         {`${personalData.firstName}'s Approach to Work`}
//                                     </h2>
//                                     <div className="d-md-none d-block">
//                                         <div className="d-flex align-items-center justify-content-between">
//                                             <h2 className="green m-0 fs-sm-20">
//                                                 {`${personalData.firstName}'s Approach to Work`}
//                                             </h2>
//                                             <img
//                                                 src={neuroda}
//                                                 alt="neuroda"
//                                                 width="30%"
//                                             />
//                                         </div>
//                                     </div>
//                                     <ul className="py-4 px-xxl-6 px-lg-5 px-md-4 ps-3 mb-0 padding-left ">
//                                         {wipData.analysis.approach_to_work.strengths.map(
//                                             (a, index) => (
//                                                 <li
//                                                     className="mb-0   mb-3"
//                                                     key={index}
//                                                 >
//                                                     {a}
//                                                 </li>
//                                             )
//                                         )}
//                                     </ul>
//                                     <div className="flare3"></div>
//                                     <div className="flare4"></div>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {wipData.analysis.key_traits.strengths.length > 0 && (
//                         <div className={'d-flex  mb-5'}>
//                             <div className="soft-skills-wrapper1 bg-grey-gradient">
//                                 <div className="border border-1 border-secondary rounded shadow-violet soft-skills1">
//                                     <h2 className="green m-0 fs-sm-20 d-none d-md-block">
//                                         {`Key traits exhibited by ${personalData.firstName} at the workplace`}
//                                     </h2>
//                                     <div className="d-md-none d-block">
//                                         <div className="d-flex align-items-center justify-content-between">
//                                             <h2 className="green m-0 fs-sm-20">
//                                                 {`Key traits exhibited by ${personalData.firstName} at the workplace`}
//                                             </h2>
//                                             <img
//                                                 src={neuroda}
//                                                 alt="neuroda"
//                                                 width="30%"
//                                             />
//                                         </div>
//                                     </div>
//                                     <ul className="py-4 px-xxl-6 px-lg-5 px-md-4 ps-3 mb-0 padding-left ">
//                                         {wipData.analysis.key_traits.strengths.map(
//                                             (a, index) => (
//                                                 <li
//                                                     className="mb-0  mb-3"
//                                                     key={index}
//                                                 >
//                                                     {a}
//                                                 </li>
//                                             )
//                                         )}
//                                     </ul>
//                                     <div className="flare3"></div>
//                                     <div className="flare4"></div>
//                                 </div>
//                             </div>
//                             <div className="neuroda1 d-none d-md-block">
//                                 <img src={neuroda} alt="neuroda" width="100%" />
//                             </div>
//                         </div>
//                     )}
//                 </div>
//                 <div
//                     className="mb-3"
//                     style={{
//                         width: '100%',
//                         position: 'relative',
//                     }}
//                 >
//                     <div className="flare2"></div>
//                 </div>

//                 <div className="border border-1 border-secondary rounded p-xxl-6 p-lg-5 p-md-4 p-3 ">
//                     <div>
//                         <h2 className="text-uppercase fw-bold ls-8 mb-4 ">
//                             Recommended Job Functions
//                         </h2>
//                         <h3 className="fw-bold green">
//                             {personalData.firstName} will be good at roles that
//                             involve the following functions
//                         </h3>

//                         <h5 className="mb-0 ">
//                             Job functions refers to generalistic functional
//                             aspects of tasks that need to be done at the
//                             workplace
//                         </h5>
//                     </div>
//                     <br />
//                     <div className="mt-5 px-lg-3">
//                         {wipData.jobFunction.map((job, idx) => (
//                             <div
//                                 key={idx}
//                                 className="d-flex flex-md-row flex-column justify-content-md-around gap-lg-5 align-items-center p-0 mt-5 mt-md-0 mb-md-5"
//                             >
//                                 <div
//                                     className="border border-1 rounded-circle w-jobfunction bg-green p-xxl-7 p-3 mb-3 mb-md-0  
//                    shadow-light-green"
//                                 >
//                                     <img
//                                         src={traitsIcons[idx]}
//                                         alt={`traitsicon${idx + 1}`}
//                                         style={{
//                                             width: idx === 1 ? '115%' : '100%',
//                                         }}
//                                     />
//                                 </div>
//                                 <div className="jobfunction-traitsheading">
//                                     <h5 className="mb-3 text-white ">
//                                         {/* {traitsHeading[idx]} */}
//                                         {job.job_function}
//                                     </h5>
//                                     <p className="mb-0   text-white ">
//                                         {/* {traitsText[idx]} */}
//                                         {job.description}
//                                     </p>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//                 <div
//                     className="mb-3"
//                     style={{
//                         width: '100%',
//                         position: 'relative',
//                     }}
//                 >
//                     <div className="flare2"></div>
//                 </div>

//                 <div
//                     style={{
//                         width: '100%',
//                         position: 'relative',
//                     }}
//                 >
//                     <div className="flare"></div>
//                 </div>
//                 <div className="border border-1 border-secondary rounded p-xxl-6 p-lg-5 p-md-4 p-3 mb-3">
//                     <h2 className="text-uppercase fw-bold ls-8">
//                         learning experience snapshot
//                     </h2>
//                     <div className="d-flex justify-content-between flex-wrap mt-xxl-6 mt-5 d-md-block d-none">
//                         <div className="d-flex mb-5 justify-content-between flex-wrap">
//                             <div className="col-lg-3 col-md-3 col-5 p-0 ">
//                                 <div
//                                     style={{
//                                         width: '100%',
//                                         position: 'relative',
//                                     }}
//                                 >
//                                     <div className="flare5"></div>
//                                 </div>
//                                 <div className="border border-1 rounded-4 bg-black-gradient p-xxl-8 p-md-4 p-2 mx-lg-1">
//                                     <img
//                                         src={trainingicon1}
//                                         alt="trainingicon1"
//                                         width="70%"
//                                     />
//                                 </div>
//                                 <div className="d-flex mx-lg-2 mt-2">
//                                     <h1 className="green fw-bold">
//                                         {Number.isInteger(
//                                             wipData.learningExperience
//                                                 .workplace_tackled
//                                         ) &&
//                                             wipData.learningExperience
//                                                 .workplace_tackled < 10
//                                             ? `0${wipData.learningExperience.workplace_tackled}`
//                                             : wipData.learningExperience
//                                                 .workplace_tackled}
//                                     </h1>

//                                     <span className="ms-2 ms-xxl-4  mt-1 ">
//                                         {
//                                             wipData.learningExperience
//                                                 .workplace_tackled_Desc
//                                         }
//                                     </span>
//                                 </div>
//                             </div>
//                             {/* <div className="divider d-none d-md-block"></div> */}

//                             <div className="col-lg-3 col-md-3 col-5 p-0 ">
//                                 <div className="border border-1 rounded-4 bg-black-gradient p-xxl-8 p-4 mx-lg-1">
//                                     <img
//                                         src={trainingicon2}
//                                         alt="trainingicon2"
//                                         width="100%"
//                                     />
//                                 </div>
//                                 <div
//                                     style={{
//                                         width: '100%',
//                                         position: 'relative',
//                                     }}
//                                 >
//                                     <div className="flare6"></div>
//                                 </div>
//                                 <div className="d-flex mx-lg-2 mt-2">
//                                     <h1 className="green  fw-bold">
//                                         {Number.isInteger(
//                                             wipData.learningExperience
//                                                 .unique_models
//                                         ) &&
//                                             wipData.learningExperience
//                                                 .unique_models < 10
//                                             ? `0${wipData.learningExperience.unique_models}`
//                                             : wipData.learningExperience
//                                                 .unique_models}
//                                     </h1>

//                                     <span className="ms-2 ms-xxl-4  mt-1  ">
//                                         {
//                                             wipData.learningExperience
//                                                 .unique_models_Desc
//                                         }
//                                     </span>
//                                 </div>
//                             </div>
//                             {/* <div className="divider d-none d-md-block"></div> */}

//                             <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0 mb-md-4 mb-lg-3">
//                                 <div className="border border-1 p-xxl-8 bg-black-gradient rounded-4 p-4 mx-lg-1">
//                                     <img
//                                         src={trainingicon3}
//                                         alt="trainingicon3"
//                                         width="100%"
//                                     />
//                                 </div>

//                                 <div className="d-flex mx-lg-2 max-w-85 mt-2">
//                                     <h1 className="green fw-bold">
//                                         {Number.isInteger(
//                                             wipData.learningExperience
//                                                 .personalities
//                                         ) &&
//                                             wipData.learningExperience
//                                                 .personalities < 10
//                                             ? `0${wipData.learningExperience.personalities}`
//                                             : wipData.learningExperience
//                                                 .personalities}
//                                     </h1>
//                                     <span className="ms-2 ms-xxl-4  mt-1 ">
//                                         {
//                                             wipData.learningExperience
//                                                 .personalities_Desc
//                                         }
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="d-flex mb-5 justify-content-between flex-wrap">
//                             <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0  mb-md-4 mb-lg-3">
//                                 <div className="border border-1 bg-black-gradient p-xxl-8 rounded-4 p-4 mx-lg-1">
//                                     <img
//                                         src={trainingicon4}
//                                         alt="trainingicon4"
//                                         width="100%"
//                                     />
//                                 </div>

//                                 <div className="d-flex mx-lg-2 mt-2">
//                                     <h1 className="green  fw-bold ">
//                                         {Number.isInteger(
//                                             wipData.learningExperience
//                                                 .professional_emails
//                                         ) &&
//                                             wipData.learningExperience
//                                                 .professional_emails < 10
//                                             ? `0${wipData.learningExperience.professional_emails}`
//                                             : wipData.learningExperience
//                                                 .professional_emails}
//                                     </h1>
//                                     <span className="ms-2 ms-xxl-4  mt-1  ">
//                                         {
//                                             wipData.learningExperience
//                                                 .professional_emails_Desc
//                                         }
//                                     </span>
//                                 </div>
//                             </div>

//                             <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0  mb-md-4 mb-lg-3">
//                                 <div className="border border-1 bg-black-gradient p-xxl-8 rounded-4 p-4 mx-lg-1">
//                                     <img
//                                         src={trainingicon5}
//                                         alt="trainingicon5"
//                                         width="100%"
//                                     />
//                                 </div>

//                                 <div className="d-flex mx-lg-2 max-w-100 mt-2">
//                                     <h1 className="green  fw-bold ">
//                                         {kamaiData.kamai}
//                                     </h1>
//                                     <span className="ms-2 ms-xxl-4  mt-1  ">
//                                         {
//                                             wipData.learningExperience
//                                                 .kamai_token_Desc
//                                         }
//                                     </span>
//                                 </div>
//                             </div>

//                             <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0  mb-md-4 mb-lg-3">
//                                 <div className="border border-1 bg-black-gradient p-xxl-8 rounded-4 p-4 mx-lg-1">
//                                     <img
//                                         src={trainingicon6}
//                                         alt="trainingicon6"
//                                         width="100%"
//                                     />
//                                 </div>

//                                 <div className="d-flex mx-lg-2 max-w-100 mt-2">
//                                     <h1 className="green  fw-bold ">
//                                         {Number.isInteger(
//                                             wipData.learningExperience
//                                                 .workplace_simulation
//                                         ) &&
//                                             wipData.learningExperience
//                                                 .workplace_simulation < 10
//                                             ? `0${wipData.learningExperience.workplace_simulation}`
//                                             : wipData.learningExperience
//                                                 .workplace_simulation}
//                                     </h1>
//                                     <span className="ms-2 ms-xxl-4  mt-1  ">
//                                         {
//                                             wipData.learningExperience
//                                                 .workplace_simulation_Desc
//                                         }
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                     {/* mobile view */}

//                     <div className="d-flex justify-content-between flex-wrap mt-xxl-6 mt-5 px-lg-5 d-block d-md-none">
//                         <div className="d-flex mb-5 justify-content-between flex-wrap">
//                             <div className="col-lg-3 col-md-3 col-5 p-0 ">
//                                 <div
//                                     style={{
//                                         width: '100%',
//                                         position: 'relative',
//                                     }}
//                                 >
//                                     <div className="flare5"></div>
//                                 </div>
//                                 <div className="border border-1 bg-black-gradient rounded-4 p-xxl-8 p-md-4 p-4 mx-lg-4">
//                                     <img
//                                         src={trainingicon1}
//                                         alt="trainingicon1"
//                                         width="70%"
//                                     />
//                                 </div>
//                                 <div className="d-flex mx-lg-4 mt-2">
//                                     <h1 className="green fs-1 fw-bold">
//                                         {
//                                             wipData.learningExperience
//                                                 .workplace_tackled
//                                         }
//                                     </h1>
//                                     <span className="ms-2 ms-xxl-4 ">
//                                         {
//                                             wipData.learningExperience
//                                                 .workplace_tackled_Desc
//                                         }
//                                     </span>
//                                 </div>
//                             </div>
//                             {/* <div className="divider d-none d-md-block"></div> */}

//                             <div className="col-lg-3 col-md-3 col-5 p-0 ">
//                                 <div className="border border-1 rounded-4 bg-black-gradient p-xxl-8 p-4 mx-lg-4">
//                                     <img
//                                         src={trainingicon2}
//                                         alt="trainingicon2"
//                                         width="100%"
//                                     />
//                                 </div>
//                                 <div
//                                     style={{
//                                         width: '100%',
//                                         position: 'relative',
//                                     }}
//                                 >
//                                     <div className="flare6"></div>
//                                 </div>
//                                 <div className="d-flex mx-lg-4 mt-2">
//                                     <h1 className="green fs-1 fw-bold">
//                                         {
//                                             wipData.learningExperience
//                                                 .unique_models
//                                         }
//                                     </h1>
//                                     <span className="ms-2 ms-xxl-4   ">
//                                         {
//                                             wipData.learningExperience
//                                                 .unique_models_Desc
//                                         }
//                                     </span>
//                                 </div>
//                             </div>
//                             {/* <div className="divider d-none d-md-block"></div> */}

//                             <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0 mb-md-4 mb-lg-3">
//                                 <div
//                                     style={{
//                                         width: '100%',
//                                         position: 'relative',
//                                     }}
//                                 >
//                                     <div className="flare5"></div>
//                                 </div>
//                                 <div className="border border-1 p-xxl-8 rounded-4 p-4 bg-black-gradient mx-lg-4 ">
//                                     <img
//                                         src={trainingicon3}
//                                         alt="trainingicon3"
//                                         width="100%"
//                                     />
//                                 </div>

//                                 <div className="d-flex mx-lg-2 max-w-100 mt-2">
//                                     <h1 className="green fs-1 fw-bold">
//                                         {
//                                             wipData.learningExperience
//                                                 .personalities
//                                         }
//                                     </h1>
//                                     <span className="ms-2 ms-xxl-4   word-break">
//                                         {
//                                             wipData.learningExperience
//                                                 .personalities_Desc
//                                         }
//                                     </span>
//                                 </div>
//                             </div>

//                             <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0  mb-md-4 mb-lg-3">
//                                 <div className="border border-1 bg-black-gradient p-xxl-8 rounded-4 p-4 mx-lg-4">
//                                     <img
//                                         src={trainingicon4}
//                                         alt="trainingicon4"
//                                         width="100%"
//                                     />
//                                 </div>

//                                 <div className="d-flex mx-lg-4 max-w-100 mt-2">
//                                     <h1 className="green fs-1 fw-bold">
//                                         {
//                                             wipData.learningExperience
//                                                 .professional_emails
//                                         }
//                                     </h1>
//                                     <span className="ms-2 ms-xxl-4   word-break">
//                                         {
//                                             wipData.learningExperience
//                                                 .professional_emails_Desc
//                                         }
//                                     </span>
//                                 </div>
//                             </div>

//                             <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0  mb-md-4 mb-lg-3">
//                                 <div className="border border-1 bg-black-gradient p-xxl-8 rounded-4 p-4 mx-lg-4">
//                                     <img
//                                         src={trainingicon5}
//                                         alt="trainingicon5"
//                                         width="100%"
//                                     />
//                                 </div>

//                                 <div className="d-flex mx-lg-4 mt-2">
//                                     <h1 className="green fs-1 fw-bold">
//                                         {kamaiData.kamai}
//                                     </h1>
//                                     <span className="ms-2 ms-xxl-4 ">
//                                         {
//                                             wipData.learningExperience
//                                                 .kamai_token_Desc
//                                         }
//                                     </span>
//                                 </div>
//                             </div>

//                             <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0  mb-md-4 mb-lg-3">
//                                 <div className="border border-1 p-xxl-8 bg-black-gradient rounded-4 p-4 mx-lg-4">
//                                     <img
//                                         src={trainingicon6}
//                                         alt="trainingicon6"
//                                         width="100%"
//                                     />
//                                 </div>

//                                 <div className="d-flex mx-lg-4 max-w-100 mt-2">
//                                     <h1 className="green fs-1 fw-bold">
//                                         {
//                                             wipData.learningExperience
//                                                 .workplace_simulation
//                                         }
//                                     </h1>
//                                     <span className="ms-2 ms-xxl-4   word-break">
//                                         {
//                                             wipData.learningExperience
//                                                 .workplace_simulation_Desc
//                                         }
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                     {/* mobile view end*/}
//                 </div>

//                 <div className="mt-3 border border-1 border-secondary rounded p-xxl-6 p-lg-5 p-md-4 p-3 ">
//                     <h4 className="text-center fw-bold text-uppercase">
//                         Badges Achieved
//                     </h4>
//                     <div className="d-flex flex-column flex-md-row p-4 p-md-0 justify-content-evenly align-items-center">
//                         {/* {Object.keys().map(
//               (badgeName, index) =>
//                 wipData.badges[badgeName] &&
//                 badgeIcons[badgeName] && (
//                   <div key={index} className=" w-xxl-7">
//                     <img
//                       src={badgeIcons[badgeName]}
//                       alt={badgeName}
//                       width="100%"
//                     />
//                   </div>
//                 )
//             )} */}

//                         {ceoInMaking && (
//                             <img
//                                 src={badgeIcons['ceo_in_making']}
//                                 alt={'ceo_in_making'}
//                                 className="w-30"
//                             />
//                         )}

//                         {smartGeneralist && (
//                             <img
//                                 src={badgeIcons['smart_professional']}
//                                 alt={'smart_professional'}
//                                 className="w-30"
//                             />
//                         )}

//                         {problemSolver && (
//                             <img
//                                 src={badgeIcons['future_manager']}
//                                 alt={'future_manager'}
//                                 className="w-30"
//                             />
//                         )}
//                     </div>
//                 </div>

//                 {/* ------------------------------------------------------------working below----------------------------------------------------------- */}

//                 <div className="d-flex justify-content-between align-items-center px-lg-4 px-0 mt-3">
//                     <div>
//                         <QRCode
//                             value={wipData.playerURL}
//                             size={80}
//                             level={'H'}
//                             includeMargin={true}
//                             bgColor={'#ffffff'}
//                             fgColor={'#000000'}
//                         />
//                         <br />
//                         Scan to Verify
//                     </div>

//                     <button
//                         onClick={handleDownloadPdf}
//                         className="btn btn-primary text-black"
//                         style={{ background: '#ffffff' }}
//                     >
//                         Download PDF
//                     </button>
//                 </div>
//                 <br />
//                 <br />
//                 <br />
//                 <p className="px-2 px-md-0 fs-14">
//                     This certificate above verifies that{' '}
//                     <span className="text-primary">
//                         {' '}
//                         {personalData.firstName} {personalData.lastName}{' '}
//                     </span>
//                     successfully completed the game Master futuristic soft
//                     skills and roleplay scenarios in workplace Simulation on
//                     <span> 12/09/2024 </span> at{' '}
//                     <span className="text-primary">
//                         {' '}
//                         Workverse ( X billion Skills lab)
//                     </span>
//                     . The certificate indicates the entire course was completed
//                     as validated by the student.
//                 </p>
//             </section>
//         </>
//     );
// }

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useRef } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import logo from './images/Logo.png';
// import badge3 from "./images/badge3.png";

import traitsicon1 from './images/Vector (4).png';
import traitsicon2 from './images/Vector (5).png';
import traitsicon3 from './images/Vector (6).png';
// import playerImg from "./images/player_img.png";
// import traitsicon4 from "./images/Vector.svg";
import badgeicon1 from './images/badge1.png';
import badgeicon2 from './images/badge2.png';
import badgeicon4 from './images/badge4.png';
import neuroda from './images/Neuroda.png';
// import communicate from "./images/COMMUNICATE.png";
import badgeFrame1 from './images/badgeframe1.png';
import badgeFrame2 from './images/badgeframe2.png';
import badgeFrame3 from './images/badgeframe3.png';
import badgeText1 from './images/badgetext2.png';
import badgeText2 from './images/badgetext3.png';
import badgeText3 from './images/badgetext1.png';
// import solve from "./images/SOLVE.png";
import think from './images/THINK.png';
import './profile.css';
import Hero from '../Component/Hero.jsx'
import Proficiency from './Proficiency.jsx';
import Analysis from './Analysis.jsx';
import Experience from './Experience.jsx';
import Badges from './Badges.jsx';
import Footer from './Footer.jsx';
import Leftflare from './Shared/Leftflare.jsx';
import Rightflare from './Shared/Rightflare.jsx';

const getColorAndLabel = (score) => {
    let color, label;

    if (score < 55) {
        color = '#19f2fa';
        label = 'Capable';
    } else if (score < 77) {
        color = '#02b372';
        label = 'Impressive';
    } else if (score <= 100) {
        color = '#ff529d';
        label = 'Exceptional';
    }

    return { color, label };
};

export default function Profile() {
    const [wipData, setWipData] = useState();
    const [personalData, setPersonalData] = useState();
    const [kamaiData, setKamaiData] = useState();
    const [ceoInMaking, setCEOInMaking] = useState(false);
    const [smartGeneralist, setSmartGeneralist] = useState(false);
    const [problemSolver, setProblemSolver] = useState(false);
    const [error] = useState();
    const sectionRef = useRef();

    const badgeIcons = {
        smart_professional: badgeicon1,
        future_manager: badgeicon2,
        ceo_in_making: badgeicon4,
    };

    const traitsIcons = [traitsicon1, traitsicon2, traitsicon3];

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const wipId = urlParams.get('wipId');

    useEffect(() => {
        if (wipId) {
            const fetchData = async () => {
                try {
                    const [wipResponse, personalResponse, kamaiDataResponse] =
                        await Promise.all([
                            axios.get(
                                `https://api.engine.workverse.in/api/v2/users/wip/${wipId}`
                            ),
                            axios.get(
                                `https://api.engine.workverse.in/api/v2/users/info/${wipId}`
                            ),
                            axios.get(
                                `https://api.engine.workverse.in/api/v2/users/kamai/70ccc26a-7f99-4069-b4ef-3a347bd0908d`
                            ),
                        ]);

                    setWipData(wipResponse.data);
                    setPersonalData(personalResponse.data);
                    setKamaiData(kamaiDataResponse.data);

                    setCEOInMaking(
                        kamaiDataResponse.data.playerBadgeData.includes(
                            'CEO In the Making'
                        )
                    );
                    setSmartGeneralist(
                        kamaiDataResponse.data.playerBadgeData.includes(
                            'Emerging Smart Generalist'
                        )
                    );
                    setProblemSolver(
                        kamaiDataResponse.data.playerBadgeData.includes(
                            'Seasoned Problem Solver'
                        )
                    );
                } catch (error) {
                    console.error('Error fetching data:', error.message);
                }
            };
            fetchData(); // Call fetchData without passing wipId as an argument
        }
    }, [wipId]);

    const handleDownloadPdf = () => {
        const input = sectionRef.current;
        html2canvas(input, { scale: 0.9 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            const imgHeight = (canvasWidth * pdfHeight) / pdfWidth;
            let heightLeft = canvasHeight;
            let position = 0;

            // Add the first page
            pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
            heightLeft -= pdfHeight;

            // Add additional pages if necessary
            while (heightLeft > 0) {
                position = heightLeft - canvasHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
                heightLeft -= pdfHeight;
            }

            pdf.save('pfd.pdf');
        });
    };

    if (error) return <div className="p-4 text-white">Input: {error}</div>;
    if (!wipData) return <div className="p-4 text-white">Loading...</div>;

    const { overallSkillScore } = wipData.softSkills;
    const { color, label } = getColorAndLabel(overallSkillScore);

    const { subskills } = wipData.softSkills.useCases[0];

    function getBadge(badges) {
        if (badges.ceoInMaking) {
            return { frame: badgeFrame3, text: badgeText3 };
        } else if (badges.problemSolver) {
            return { frame: badgeFrame2, text: badgeText2 };
        } else if (badges.smartGeneralist) {
            return { frame: badgeFrame1, text: badgeText1 };
        } else {
            return null;
        }
    }



    return (
        <>
            <section className="p-3 p-xxl-5 text-white wip-bg" ref={sectionRef}>
                <Leftflare />
                <Hero
                    personalData={personalData}
                    wipData={wipData}
                    badges={{ problemSolver, smartGeneralist, ceoInMaking }}
                    problemSolver={problemSolver}
                    smartGeneralist={smartGeneralist}
                    ceoInMaking={ceoInMaking}
                    logo={logo} // Pass the logo
                />
                <Rightflare />
                <Proficiency
                    wipData={wipData}
                    label={label}
                    CircularProgressbar={CircularProgressbar}
                    buildStyles={buildStyles}
                    color={color}
                    subskills={subskills}
                    getColorAndLabel={getColorAndLabel}
                    think={think}
                />
                
              <Leftflare />
                <Analysis
                    neuroda={neuroda}
                    personalData={personalData}
                    wipData={wipData}
                />

               <Rightflare />
                <Experience
                    wipData={wipData}
                    kamaiData={kamaiData}
                />

                <Leftflare />
                <Badges
                    ceoInMaking={ceoInMaking}
                    smartGeneralist={smartGeneralist}
                    problemSolver={problemSolver}
                />

                <Footer
                    wipData={wipData}
                    handleDownloadPdf={handleDownloadPdf}
                    personalData={personalData}
                />
            </section>
        </>
    );
}
