import React from "react";

// StarRating Component
export const StarRating = ({ rating }) => {
  const stars = Array.from({ length: 5 }, (_, index) => {
    const fill = Math.min(Math.max(rating - index, 0), 1) * 100;

    return (
      <svg
        key={index}
        width="15"
        height="15"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id={`grad-${index}`}>
            <stop offset={`${fill}%`} stopColor="#ffcd0c" />
            <stop offset={`${fill}%`} stopColor="lightgray" />
          </linearGradient>
        </defs>
        <path
          d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z"
          fill={`url(#grad-${index})`}
        />
      </svg>
    );
  });

  return (
    <div className="d-flex flex-row align-items-center ">
      <span className="fw-bold me-1 white "> {rating} </span>
      {stars}
      <span className="white ms-1">(5,000)</span>
    </div>
  );
};
