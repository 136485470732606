import React from 'react';

const Leftflare = () => {
    return (
        <div

            style={{
                width: '100%',
                position: 'relative',
            }}
        >
            <div className="flare"></div>
        </div>
    );
}

export default Leftflare;
