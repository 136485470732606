import React from 'react';

const Proficiency = ({wipData , label , CircularProgressbar,buildStyles,color,subskills,getColorAndLabel,think}) => {
  return (
         <div className="border border-1 border-secondary rounded p-xxl-6 p-lg-5 p-md-4 p-3 mb-4">
                        <h2 className="text-uppercase fw-bold ls-8 mb-5">
                            Soft skills proficiency
                        </h2>
    
                        <div className="mb-5">
                            <div className="row">
                                <div className="col-12 col-md-8  align-items-center">
                                    <h3 className="text-uppercase fw-bold green">
                                        Creative Problem Solving
                                    </h3>
                                    <p className="mb-4 mb-md-0">
                                        Creative problem solving in the workplace
                                        involves using innovative thinking and
                                        unconventional approaches to address
                                        challenges and enhance outcomes.
                                    </p>
                                </div>
    
                                <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
                                    <div className="custom-loader-wrapper">
                                        <div
                                            className={`skill circular-progress-${label.toLowerCase()}`}
                                        >
                                            <CircularProgressbar
                                                value={
                                                    wipData.softSkills
                                                        .overallSkillScore
                                                }
                                                text={label}
                                                strokeWidth={10}
                                                styles={buildStyles({
                                                    textSize: '14px',
                                                    pathColor: color,
                                                    textColor: color,
                                                    trailColor: '#2c2c2c',
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div className=" mb-5 ">
                            <div className=" border-start ps-3 ">
                                <h3 className="green m-0">
                                    Finding Smartest Alternative
                                </h3>
                                <p>
                                    Innovative, resourceful approach to
                                    problem-solving that emphasizes simplicity,
                                    adaptability, and creativity by making the most
                                    of available resources and challenging
                                    conventional methods.
                                </p>
                            </div>
                            <div className="d-flex flex-column-reverse flex-md-row align-items-center justify-content-lg-between gap-md-3 gap-lg-0 align-items-center">
                                <div className="d-md-flex justify-content-between w-55">
                                    {subskills.map(
                                        (
                                            { subskillName, subskillScore },
                                            index
                                        ) => {
                                            const { color, label } =
                                                getColorAndLabel(subskillScore);
    
                                            return (
                                                <div
                                                    className="custom-loader-wrapper mb-4 mb-md-0"
                                                    key={index}
                                                >
                                                    <div
                                                        className={`circular-progress-${label.toLowerCase()}`}
                                                    >
                                                        <CircularProgressbar
                                                            value={subskillScore}
                                                            text={label}
                                                            strokeWidth={10}
                                                            styles={buildStyles({
                                                                textSize: '14px',
                                                                pathColor: color,
                                                                textColor: color,
                                                                trailColor:
                                                                    '#2c2c2c',
                                                            })}
                                                        />
                                                    </div>
                                                    <p className="mb-0 fs-12">
                                                        {subskillName}
                                                    </p>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
    
                                <div className="w-xxl-6">
                                    <img src={think} alt="think" width="100%" />
                                </div>
                            </div>
                        </div>
    
                        <div className="d-flex justify-content-between flex-column flex-md-row mb-5 ">
                            <div className="col-lg-3 col-md-3 col-12 p-0 mt-md-0  mb-5 mb-md-5 text-center text-md-start">
                                <h4 className="fw-bold blue mb-3">Capable</h4>
                                <p className="m-0 ">
                                    {' '}
                                    Demonstrates a foundational application of the
                                    skill, able to perform tasks with occasional
                                    support in typical workplace scenarios.,
                                </p>
                            </div>
                            <div className="divider2 d-none d-md-block"></div>
                            <div className="col-lg-3 col-md-3 col-12 p-0  mt-md-0 mb-5 mb-md-5  text-center text-md-start">
                                <h4 className="fw-bold blue-green  mb-3">
                                    Impressive
                                </h4>
                                <p className="m-0 ">
                                    Exhibits a strong command of the skill, capable
                                    of independently handling complex tasks and
                                    contributing to team goals.
                                </p>
                            </div>
                            <div className="divider2 d-none d-md-block"></div>
                            <div className="col-lg-3 col-md-3 col-12 p-0  mt-lg-0  mb-md-4 mb-lg-3  text-center text-md-start">
                                <h4 className="fw-bold pink  mb-3">Exceptional</h4>
                                <p className="m-0 ">
                                    Displays mastery in the skill, consistently
                                    delivering quality results, and demonstrating
                                    innovative approaches in addressing challenging
                                    workplace situations.
                                </p>
                            </div>
                        </div>
                    </div>
  );
}

export default Proficiency;
