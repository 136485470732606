import React from 'react';

const Rightflare = () => {
    return (
        <div
            className="mb-3"
            style={{
                width: '100%',
                position: 'relative',
            }}
        >
            <div className="flare2"></div>
        </div>
    );
}

export default Rightflare;
