import React from 'react';
import trainingicon1 from './images/Vector (3).png';
import trainingicon2 from './images/Vector.png';
import trainingicon3 from './images/Vector (1).png';
import trainingicon4 from './images/Engage Email  (Icon).svg';
import trainingicon5 from './images/Kamai Token (Icon).svg';
import trainingicon6 from './images/Vector (2).png';

const Experience = ({ wipData,kamaiData}) => {
  return (
    <div className="border border-1 border-secondary rounded p-xxl-6 p-lg-5 p-md-4 p-3 mb-3">
    <h2 className="text-uppercase fw-bold ls-8">
        learning experience snapshot
    </h2>
    <div className="d-flex justify-content-between flex-wrap mt-xxl-6 mt-5 d-md-block d-none">
        <div className="d-flex mb-5 justify-content-between flex-wrap">
            <div className="col-lg-3 col-md-3 col-5 p-0 ">
                <div
                    style={{
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <div className="flare5"></div>
                </div>
                <div className="border border-1 rounded-4 bg-black-gradient p-xxl-8 p-md-4 p-2 mx-lg-1">
                    <img
                        src={trainingicon1}
                        alt="trainingicon1"
                        width="70%"
                    />
                </div>
                <div className="d-flex mx-lg-2 mt-2">
                    <h1 className="green fw-bold">
                        {Number.isInteger(
                            wipData.learningExperience
                                .workplace_tackled
                        ) &&
                            wipData.learningExperience
                                .workplace_tackled < 10
                            ? `0${wipData.learningExperience.workplace_tackled}`
                            : wipData.learningExperience
                                .workplace_tackled}
                    </h1>

                    <span className="ms-2 ms-xxl-4  mt-1 ">
                        {
                            wipData.learningExperience
                                .workplace_tackled_Desc
                        }
                    </span>
                </div>
            </div>
            {/* <div className="divider d-none d-md-block"></div> */}

            <div className="col-lg-3 col-md-3 col-5 p-0 ">
                <div className="border border-1 rounded-4 bg-black-gradient p-xxl-8 p-4 mx-lg-1">
                    <img
                        src={trainingicon2}
                        alt="trainingicon2"
                        width="100%"
                    />
                </div>
                <div
                    style={{
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <div className="flare6"></div>
                </div>
                <div className="d-flex mx-lg-2 mt-2">
                    <h1 className="green  fw-bold">
                        {Number.isInteger(
                            wipData.learningExperience
                                .unique_models
                        ) &&
                            wipData.learningExperience
                                .unique_models < 10
                            ? `0${wipData.learningExperience.unique_models}`
                            : wipData.learningExperience
                                .unique_models}
                    </h1>

                    <span className="ms-2 ms-xxl-4  mt-1  ">
                        {
                            wipData.learningExperience
                                .unique_models_Desc
                        }
                    </span>
                </div>
            </div>
            {/* <div className="divider d-none d-md-block"></div> */}

            <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0 mb-md-4 mb-lg-3">
                <div className="border border-1 p-xxl-8 bg-black-gradient rounded-4 p-4 mx-lg-1">
                    <img
                        src={trainingicon3}
                        alt="trainingicon3"
                        width="100%"
                    />
                </div>

                <div className="d-flex mx-lg-2 max-w-85 mt-2">
                    <h1 className="green fw-bold">
                        {Number.isInteger(
                            wipData.learningExperience
                                .personalities
                        ) &&
                            wipData.learningExperience
                                .personalities < 10
                            ? `0${wipData.learningExperience.personalities}`
                            : wipData.learningExperience
                                .personalities}
                    </h1>
                    <span className="ms-2 ms-xxl-4  mt-1 ">
                        {
                            wipData.learningExperience
                                .personalities_Desc
                        }
                    </span>
                </div>
            </div>
        </div>

        <div className="d-flex mb-5 justify-content-between flex-wrap">
            <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0  mb-md-4 mb-lg-3">
                <div className="border border-1 bg-black-gradient p-xxl-8 rounded-4 p-4 mx-lg-1">
                    <img
                        src={trainingicon4}
                        alt="trainingicon4"
                        width="100%"
                    />
                </div>

                <div className="d-flex mx-lg-2 mt-2">
                    <h1 className="green  fw-bold ">
                        {Number.isInteger(
                            wipData.learningExperience
                                .professional_emails
                        ) &&
                            wipData.learningExperience
                                .professional_emails < 10
                            ? `0${wipData.learningExperience.professional_emails}`
                            : wipData.learningExperience
                                .professional_emails}
                    </h1>
                    <span className="ms-2 ms-xxl-4  mt-1  ">
                        {
                            wipData.learningExperience
                                .professional_emails_Desc
                        }
                    </span>
                </div>
            </div>

            <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0  mb-md-4 mb-lg-3">
                <div className="border border-1 bg-black-gradient p-xxl-8 rounded-4 p-4 mx-lg-1">
                    <img
                        src={trainingicon5}
                        alt="trainingicon5"
                        width="100%"
                    />
                </div>

                <div className="d-flex mx-lg-2 max-w-100 mt-2">
                    <h1 className="green  fw-bold ">
                        {kamaiData.kamai}
                    </h1>
                    <span className="ms-2 ms-xxl-4  mt-1  ">
                        {
                            wipData.learningExperience
                                .kamai_token_Desc
                        }
                    </span>
                </div>
            </div>

            <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0  mb-md-4 mb-lg-3">
                <div className="border border-1 bg-black-gradient p-xxl-8 rounded-4 p-4 mx-lg-1">
                    <img
                        src={trainingicon6}
                        alt="trainingicon6"
                        width="100%"
                    />
                </div>

                <div className="d-flex mx-lg-2 max-w-100 mt-2">
                    <h1 className="green  fw-bold ">
                        {Number.isInteger(
                            wipData.learningExperience
                                .workplace_simulation
                        ) &&
                            wipData.learningExperience
                                .workplace_simulation < 10
                            ? `0${wipData.learningExperience.workplace_simulation}`
                            : wipData.learningExperience
                                .workplace_simulation}
                    </h1>
                    <span className="ms-2 ms-xxl-4  mt-1  ">
                        {
                            wipData.learningExperience
                                .workplace_simulation_Desc
                        }
                    </span>
                </div>
            </div>
        </div>
    </div>

    {/* mobile view */}

    <div className="d-flex justify-content-between flex-wrap mt-xxl-6 mt-5 px-lg-5 d-block d-md-none">
        <div className="d-flex mb-5 justify-content-between flex-wrap">
            <div className="col-lg-3 col-md-3 col-5 p-0 ">
                <div
                    style={{
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <div className="flare5"></div>
                </div>
                <div className="border border-1 bg-black-gradient rounded-4 p-xxl-8 p-md-4 p-4 mx-lg-4">
                    <img
                        src={trainingicon1}
                        alt="trainingicon1"
                        width="70%"
                    />
                </div>
                <div className="d-flex mx-lg-4 mt-2">
                    <h1 className="green fs-1 fw-bold">
                        {
                            wipData.learningExperience
                                .workplace_tackled
                        }
                    </h1>
                    <span className="ms-2 ms-xxl-4 ">
                        {
                            wipData.learningExperience
                                .workplace_tackled_Desc
                        }
                    </span>
                </div>
            </div>
            {/* <div className="divider d-none d-md-block"></div> */}

            <div className="col-lg-3 col-md-3 col-5 p-0 ">
                <div className="border border-1 rounded-4 bg-black-gradient p-xxl-8 p-4 mx-lg-4">
                    <img
                        src={trainingicon2}
                        alt="trainingicon2"
                        width="100%"
                    />
                </div>
                <div
                    style={{
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <div className="flare6"></div>
                </div>
                <div className="d-flex mx-lg-4 mt-2">
                    <h1 className="green fs-1 fw-bold">
                        {
                            wipData.learningExperience
                                .unique_models
                        }
                    </h1>
                    <span className="ms-2 ms-xxl-4   ">
                        {
                            wipData.learningExperience
                                .unique_models_Desc
                        }
                    </span>
                </div>
            </div>
            {/* <div className="divider d-none d-md-block"></div> */}

            <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0 mb-md-4 mb-lg-3">
                <div
                    style={{
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <div className="flare5"></div>
                </div>
                <div className="border border-1 p-xxl-8 rounded-4 p-4 bg-black-gradient mx-lg-4 ">
                    <img
                        src={trainingicon3}
                        alt="trainingicon3"
                        width="100%"
                    />
                </div>

                <div className="d-flex mx-lg-2 max-w-100 mt-2">
                    <h1 className="green fs-1 fw-bold">
                        {
                            wipData.learningExperience
                                .personalities
                        }
                    </h1>
                    <span className="ms-2 ms-xxl-4   word-break">
                        {
                            wipData.learningExperience
                                .personalities_Desc
                        }
                    </span>
                </div>
            </div>

            <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0  mb-md-4 mb-lg-3">
                <div className="border border-1 bg-black-gradient p-xxl-8 rounded-4 p-4 mx-lg-4">
                    <img
                        src={trainingicon4}
                        alt="trainingicon4"
                        width="100%"
                    />
                </div>

                <div className="d-flex mx-lg-4 max-w-100 mt-2">
                    <h1 className="green fs-1 fw-bold">
                        {
                            wipData.learningExperience
                                .professional_emails
                        }
                    </h1>
                    <span className="ms-2 ms-xxl-4   word-break">
                        {
                            wipData.learningExperience
                                .professional_emails_Desc
                        }
                    </span>
                </div>
            </div>

            <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0  mb-md-4 mb-lg-3">
                <div className="border border-1 bg-black-gradient p-xxl-8 rounded-4 p-4 mx-lg-4">
                    <img
                        src={trainingicon5}
                        alt="trainingicon5"
                        width="100%"
                    />
                </div>

                <div className="d-flex mx-lg-4 mt-2">
                    <h1 className="green fs-1 fw-bold">
                        {kamaiData.kamai}
                    </h1>
                    <span className="ms-2 ms-xxl-4 ">
                        {
                            wipData.learningExperience
                                .kamai_token_Desc
                        }
                    </span>
                </div>
            </div>

            <div className="col-lg-3 col-md-3 col-5 p-0 mt-4 mt-md-0  mb-md-4 mb-lg-3">
                <div className="border border-1 p-xxl-8 bg-black-gradient rounded-4 p-4 mx-lg-4">
                    <img
                        src={trainingicon6}
                        alt="trainingicon6"
                        width="100%"
                    />
                </div>

                <div className="d-flex mx-lg-4 max-w-100 mt-2">
                    <h1 className="green fs-1 fw-bold">
                        {
                            wipData.learningExperience
                                .workplace_simulation
                        }
                    </h1>
                    <span className="ms-2 ms-xxl-4   word-break">
                        {
                            wipData.learningExperience
                                .workplace_simulation_Desc
                        }
                    </span>
                </div>
            </div>
        </div>
    </div>

    {/* mobile view end*/}
</div>
  );
}

export default Experience;
