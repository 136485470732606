import React from 'react';
// import badgeicon1 from './images/badge1.png';
// import badgeicon2 from './images/badge2.png';
// import badgeicon4 from './images/badge4.png';
import './profile.css';
import coininmaking from '../assets/ceointhemaking.png';
import futuremanager from '../assets/futuremanager.png';
import risingsmart from '../assets/risingsmartgeneralist.png';
import seasonedproblemsolver from '../assets/seasonedproblemsolver.png';

const Badges = ({ ceoInMaking, smartGeneralist, problemSolver }) => {
    // const badgeIcons = {
    //     smart_professional: badgeicon1,
    //     future_manager: badgeicon2,
    //     ceo_in_making: badgeicon4,
    // };
    return (
        <div className="mt-3 border border-1 border-secondary rounded p-xxl-6 p-lg-5 p-md-4 p-3 ">
            <h4 className="text-center fw-bold text-uppercase">
                Badges Achieved
            </h4>
            <div className="d-flex flex-column flex-md-row p-4 p-md-0 justify-content-evenly align-items-center">
                {/* {Object.keys().map(
                        (badgeName, index) =>
                            wipData.badges[badgeName] &&
                        badgeIcons[badgeName] && (
                        <div key={index} className=" w-xxl-7">
                        <img
                      src={badgeIcons[badgeName]}
                      alt={badgeName}
                      width="100%"
                        />
                    </div>
                    )
                     )} */}

                {/* {ceoInMaking && (
                            <img
                                src={badgeIcons['ceo_in_making']}
                                alt={'ceo_in_making'}
                                className="w-30"
                            />
                        )}

                        {smartGeneralist && (
                            <img
                                src={badgeIcons['smart_professional']}
                                alt={'smart_professional'}
                                className="w-30"
                            />
                        )}

                        {problemSolver && (
                            <img
                                src={badgeIcons['future_manager']}
                                alt={'future_manager'}
                                className="w-30"
                            />
                        )} */}
                {/* <div className=''>

                        <img src={coininmaking} alt="" />
                        <img src={futuremanager} alt="" />
                        <img src={risingsmart} alt="" />
                        <img src={seasonedproblemsolver} alt="" />
                        </div> */}

                <div className="container">
                    <div className="row g-3 mt-2">
                        {/* <div className="col-6 col-md-4 col-lg-3">
                            <img src={coininmaking} style={{ width: "150px", height: "150px" }} alt="" className="img-fluid" />
                            <div className='border bg-'><h2>CEO IN THE MAKING</h2></div>
                        </div> */}
                        <div className="col-6 col-md-4 col-lg-3 text-center r">
                            <img
                                src={coininmaking}
                                alt=""
                                className="img-fluid"
                                style={{ width: "150px", height: "150px", objectFit: "cover" }}
                            />
                            <div className=" text-dark py-2 mt-2 "  style={{ backgroundColor: "#F6B81F", borderRadius: "8px" }}>
                                {/* <h2 className="h5 mb-0"></h2> */}
                                <p className="h5 mb-0 ">CEO IN THE MAKING</p> 
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3 text-center">
                            <img
                                src={futuremanager}
                                alt=""
                                className="img-fluid"
                                style={{ width: "150px", height: "150px", objectFit: "cover" }}
                            />
                            <div className=" text-dark py-2 mt-2 "  style={{ backgroundColor: "#F6509D", borderRadius: "8px" }}>
                                {/* <h2 className="h5 mb-0"></h2> */}
                                <p className="h5 mb-0 ">Future Manager</p> 
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3 text-center">
                            <img
                                src={risingsmart}
                                alt=""
                                className="img-fluid"
                                style={{ width: "150px", height: "150px", objectFit: "cover" }}
                            />
                            <div className=" text-dark py-2 mt-2 "  style={{ backgroundColor: "#00EA94", borderRadius: "8px" }}>
                                <p className="h5 mb-0 ">RISING SMART GENERALIST</p> 
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3 text-center">
                            <img
                                src={seasonedproblemsolver}
                                alt=""
                                className="img-fluid"
                                style={{ width: "150px", height: "150px", objectFit: "cover" }}
                            />
                            <div className=" text-dark py-2 mt-2 "  style={{ backgroundColor: "#14E5FA", borderRadius: "8px" }}>
                                <p  className="h5 mb-0" style={{ fontSize: "6px" }}>SEASONED PROBLEM SOLVER</p>
                            </div>
                        </div>
                        {/* <div className="col-6 col-md-4 col-lg-3">
                            <img src={futuremanager} style={{ width: "150px", height: "150px" }} alt="" className="img-fluid" />
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <img src={risingsmart} style={{ width: "150px", height: "150px" }} alt="" className="img-fluid" />
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <img src={seasonedproblemsolver} style={{ width: "150px", height: "150px" }} alt="" className="img-fluid" />
                        </div> */}
                    </div>
                </div>

                {/* <img src={} alt="" />
                        <img src={} alt="" /> */}
            </div>
        </div>

    );
}

export default Badges;
